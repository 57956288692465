import { Button, message, Modal, Row } from "antd";
import React, { useState } from "react";
import OTPInput from "react-otp-input";
import "./style.css";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import axios from "axios";
import { base_url } from "../../constants";

const TwoFaModal = ({ handleVerifyOtp, loading }) => {
  const OTPVerificationModal = useSelector(
    (state) => state?.common?.OTPVerificationModal
  );
  const [otp, setOtp] = useState("");

  const handleResendOtp = async () => {
    const OTPSentTO = OTPVerificationModal?.username;
    await axios
      .post(`${base_url}auth/forgotPassword/${OTPSentTO}`)
      .then((response) => {
        message.success(`${response?.data?.message}`);
      })
      .catch((error) => {
        message.error(error?.response?.data?.message);
      });
  };

  return (
    <Modal open={OTPVerificationModal?.visible} footer={null} closable={false}>
      <Row justify={"center"}>
        <span className="heading-text text-center fs-3 ">OTP Verification</span>
        <div style={{ marginTop: "10px" }}>
          {OTPVerificationModal?.phone && (
            <p className="font-poppins text-center fs-6">
              {`Enter OTP sent to ${OTPVerificationModal?.phone}`}
            </p>
          )}
          {OTPVerificationModal?.message && (
            <p className="font-poppins text-center fs-6">
              {OTPVerificationModal?.message}
            </p>
          )}
        </div>
      </Row>
      <Row justify={"center"} style={{ marginTop: "30px" }}>
        <OTPInput
          value={otp}
          onChange={setOtp}
          numInputs={OTPVerificationModal?.phone ? 4 : 6}
          renderInput={(props) => <input {...props} />}
          inputType="tel"
          shouldAutoFocus={true}
          inputStyle={{
            width: "50px",
            height: "50px",
            margin: "0 10px",
            fontSize: "24px",
            borderRadius: "4px",
            border: "1px solid rgba(0, 0, 0, 0.3)",
          }}
        />
      </Row>
      <Row justify={"center"} style={{ marginTop: "30px" }}>
        <Button
          style={{
            width: "70%",
            backgroundColor: "#F77E0B",
            height: "56px",
            color: "white",
            fontFamily: "Poppins",
            fontWeight: "bold",
            fontSize: "20px",
          }}
          type="submit"
          htmlType="submit"
          onClick={() => handleVerifyOtp(otp, OTPVerificationModal?.message)}
          loading={loading}
        >
          <FormattedMessage id="verify" />
        </Button>
      </Row>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "12px",
        }}
      >
        <span>{"Didn’t receive OTP?"}</span>
        <span className="link">
          <a
            href="#"
            style={{
              fontWeight: "bold",
              marginLeft: 5,
              cursor: "pointer",
            }}
            onClick={handleResendOtp}
          >
            <FormattedMessage id="resend_otp" />
          </a>
        </span>
      </div>
    </Modal>
  );
};

export default TwoFaModal;
