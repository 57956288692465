import { EnvironmentOutlined } from "@ant-design/icons";
import { Button, Input, message, Form, Select } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { base_url, google_map_api_key } from "../../constants";
import ImageUpload from "../ImageUpload/ImageUpload";
import ProfileImageUpload1 from "../ImageUpload/ProfileImageUpload1";
import lodash from "lodash";
import { useNavigate } from "react-router-dom";
import usCities from "../../assets/Cities.js";
import usStates from "../../assets/States.js";
import Loader from "../GeneralComponents";
import { injectIntl } from "react-intl";
import { setOtpVerificationModal } from "../../actions/CommonActions.js";
import TwoFaModal from "../2FA/TwoFaModal.js";

function FirstPage(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const accessToken = JSON.parse(localStorage.getItem("data"));

  const data = useSelector((state) => state?.common?.fullUser);
  const [firstName, setFirstName] = useState(
    accessToken?.user_type === "contractor"
      ? data?.contractor?.first_name
      : accessToken?.user_type === "tradesman"
      ? data?.tradesman?.first_name
      : data?.homeowner?.first_name || ""
  );
  const [lastName, setLastName] = useState(
    accessToken?.user_type === "contractor"
      ? data?.contractor?.last_name
      : accessToken?.user_type === "tradesman"
      ? data?.tradesman?.last_name
      : data?.homeowner?.last_name || ""
  );
  const [company, setCompany] = useState(data?.company || "");
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState(data?.state || "");
  const [phoneNumber, setPhoneNumber] = useState(data?.phone || "");
  const [email, setEmail] = useState(data?.email || "");
  const [city, setCity] = useState(data?.city || "");
  const [country_code, setCountryCode] = useState("");
  const [companyLogo, setCompanyLogo] = useState(
    accessToken?.user_type === "contractor" ? data?.contractor?.logo : ""
  );
  const [profilePic, setProfilePic] = useState(
    accessToken?.user_type === "contractor"
      ? data?.contractor?.profile_picture
      : accessToken?.user_type === "tradesman"
      ? data?.tradesman?.profile_picture
      : data?.homeowner?.profile_picture || ""
  );
  const [location, setLocation] = useState({
    latitude: data?.latitude || "",
    longitude: data?.longitude || "",
    address: data?.location_name || "",
  });
  const [locationLoading, setLocationLoading] = useState(true);
  useEffect(() => {
    form.resetFields(["location"]);
  }, [locationLoading]);
  useEffect(() => {
    form.resetFields(["city"]);
  }, [state]);

  const handleGetLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          axios(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${google_map_api_key}`
          )
            .then((data) => {
              if (data.data.results && data.data.results.length > 0) {
                setLocation((prevLocation) => ({
                  ...prevLocation,
                  latitude,
                  longitude,
                  address: data?.data?.results[0]?.formatted_address,
                }));
                setCountryCode(data?.data?.results[0]?.plus_code?.global_code);
              }
              setLocationLoading(!locationLoading);
            })
            .catch((error) => {
              console.error(error);
            });
        },
        (error) => {
          message.error("Error getting location:", error);
        }
      );
    } else {
      message.error("Geolocation is not supported in this browser.");
    }
  };

  const handleUserUpdate = async () => {
    try {
      setLoading(true);
      await axios
        .patch(
          `${base_url}user/${accessToken?.user_id}`,
          {
            first_name: firstName,
            last_name: lastName,
            city: city,
            state: state,
            company: company,
            user_type: accessToken.user_type,
            profile_picture: profilePic,
            logo: companyLogo,
            country_code: country_code,
            latitude: location?.latitude,
            longitude: location?.longitude,
            location_name: location?.address,
            phone: phoneNumber,
            email: email,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken?.accessToken}`,
            },
          }
        )
        .then((response) => {
          setIsLoading(false);
          if (
            accessToken?.user_type === "contractor" ||
            accessToken?.user_type === "tradesman"
          ) {
            props.setCurrent((prevCurrent) => prevCurrent + 1);
          }
          setLoading(false);
          if (accessToken?.user_type === "homeowner") {
            navigate("/");
          }
        })
        .catch((error) => {
          setLoading(false);
          message.error(
            `${props.intl.formatMessage({ id: "unable_to_update" })}`
          );

          setIsLoading(false);
        });
    } catch (error) {
      if (
        error?.response?.data?.message.includes(
          "You've previously linked your bank to AeroPay with another business using this email"
        )
      ) {
        window.alert(error.response?.data?.message);
        dispatch(
          setOtpVerificationModal({
            visible: true,
            message: error.response?.data?.message,
            phone: "",
          })
        );
      }
    }
    setLoading(false);
  };

  const handleSubmit = async () => {
    if (data?.username?.includes("@") && phoneNumber) {
      try {
        setLoading(true);
        const response = await axios.post(
          `${base_url}auth/sendOTP`,
          {
            userId: data?.id,
            phone: phoneNumber,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken?.accessToken}`,
            },
          }
        );
        if (response?.data == true) {
          dispatch(
            setOtpVerificationModal({
              visible: true,
              phone: phoneNumber,
              username: data?.username,
            })
          );
        } else if (response?.data == false) {
          handleUserUpdate();
        }
      } catch (error) {
        if (
          error?.response?.data?.message ==
          "This phone is already in use. Please try with new one."
        ) {
          window.alert(
            "This phone is already in use. Please try with new one."
          );
        }
      }
    } else {
      handleUserUpdate();
    }
    setLoading(false);
  };

  const Cities = [];
  usCities.forEach((city) => {
    if (city.state === state) {
      Cities.push({
        label: city.city,
        value: city.city,
      });
    }
  });

  const handleState = (e) => {
    setCity("");
    form.setFieldValue({ city: city });
    form.resetFields(["city"]);
    setState(e);
  };

  const handleVerifyOtp = async (otp, message) => {
    setLoading(true);
    if (message) {
      try {
        await axios.post(
          `${base_url}aeropay/confirm-user`,
          {
            userId: data?.id,
            code: otp,
            phone_number: phoneNumber,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken?.accessToken}`,
            },
          }
        );
        dispatch(setOtpVerificationModal({ visible: "false", phone: "" }));
        handleUserUpdate();
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    } else {
      try {
        await axios.post(`${base_url}auth/verifyOTP`, {
          id: data?.id,
          otp: otp,
          type: true,
          phone: phoneNumber,
        });
        dispatch(setOtpVerificationModal({ visible: "false", phone: "" }));
        handleUserUpdate();
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
    setLoading(false);
  };

  return (
    <div>
      {loading && (
        <div className="search-loader">
          <Loader />
        </div>
      )}
      <Form
        onFinish={handleSubmit}
        form={form}
        initialValues={{
          firstName: firstName,
          lastName: lastName,
          companyName: company,
          phoneNumber: phoneNumber,
          state: state,
          city: city,
          location: location?.address,
        }}
      >
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <span className="email-text">
              {props.intl.formatMessage({ id: "first_name" })}
            </span>
            <Form.Item
              name="firstName"
              rules={[
                {
                  required: true,
                  message: `${props.intl.formatMessage({
                    id: "first_name_required",
                  })}`,
                },
                {
                  pattern: new RegExp(/^[A-Za-z\s]+$/),
                  message: `${props.intl.formatMessage({
                    id: "not_accept_number",
                  })}`,
                },
              ]}
            >
              <Input
                placeholder={props.intl.formatMessage({ id: "first_name" })}
                // value={firstName}
                style={{
                  height: "56px",
                  backgroundColor: "#E6E7EB",
                  marginTop: "8px",
                }}
                type="text"
                required
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Form.Item>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <span className="email-text">
              {props.intl.formatMessage({ id: "last_name" })}
            </span>
            <Form.Item
              name="lastName"
              rules={[
                {
                  required: true,
                  message: `${props.intl.formatMessage({
                    id: "last_name_required",
                  })}`,
                },
                {
                  pattern: new RegExp(/^[A-Za-z\s]+$/),
                  message: `${props.intl.formatMessage({
                    id: "not_accept_number",
                  })}`,
                },
              ]}
            >
              <Input
                placeholder={props.intl.formatMessage({ id: "last_name" })}
                // value={lastName}
                style={{
                  height: "56px",
                  backgroundColor: "#E6E7EB",
                  marginTop: "8px",
                }}
                onChange={(e) => setLastName(e.target.value)}
              />
            </Form.Item>
          </div>
        </div>
        <div className="row ">
          {accessToken.user_type === "contractor" && (
            <>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div>
                  <span className="email-text">
                    {props.intl.formatMessage({ id: "company_name" })}
                  </span>
                  <Form.Item
                    name="companyName"
                    rules={[
                      {
                        required: false,
                        message: "Company Name is required",
                      },
                      {
                        pattern: new RegExp(/^[A-Za-z\s]+$/),
                        message: `${props.intl.formatMessage({
                          id: "not_accept_number",
                        })}`,
                      },
                    ]}
                  >
                    <Input
                      placeholder={props.intl.formatMessage({
                        id: "company_placeholder",
                      })}
                      // value={company}
                      style={{
                        height: "56px",
                        backgroundColor: "#E6E7EB",
                        marginTop: "8px",
                      }}
                      onChange={(e) => setCompany(e.target.value)}
                    />
                  </Form.Item>
                </div>
                {data?.username?.includes("@") ? (
                  <div>
                    <span className="email-text ">
                      {props.intl.formatMessage({ id: "phone_number" })}{" "}
                    </span>
                    <Form.Item
                      name="phoneNumber"
                      rules={[
                        {
                          required: true,
                          message: `${props.intl.formatMessage({
                            id: "phone_number_required",
                          })}`,
                        },
                      ]}
                    >
                      <PhoneInput
                        inputProps={{
                          name: "phone",
                          required: true,
                        }}
                        // value={phoneNumber}
                        onChange={(e) => {
                          setPhoneNumber(e.startsWith("+") ? e : "+" + e);
                        }}
                        countryCodeEditable={true}
                        inputStyle={{
                          width: "100%",
                          height: "56px",
                          backgroundColor: "#E6E7EB",
                          marginTop: "8px",
                        }}
                        country={"us"}
                        className="w-100 phonenumber_field countries"
                        inputClass="contact_field"
                      />
                    </Form.Item>
                  </div>
                ) : (
                  <div>
                    <span className="email-text ">
                      {props.intl.formatMessage({ id: "email" })}{" "}
                    </span>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: `${props.intl.formatMessage({
                            id: "email_required",
                          })}`,
                        },
                      ]}
                    >
                      <Input
                        placeholder={props.intl.formatMessage({
                          id: "example@gmail.com",
                        })}
                        // value={company}
                        style={{
                          height: "56px",
                          backgroundColor: "#E6E7EB",
                          marginTop: "8px",
                        }}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Form.Item>
                  </div>
                )}
              </div>
              <div
                className="col-lg-6 col-md-12 col-sm-12 "
                style={{ height: "190px" }}
              >
                <ImageUpload
                  companyLogo={companyLogo}
                  setCompanyLogo={setCompanyLogo}
                />
              </div>
            </>
          )}
          {accessToken.user_type !== "contractor" &&
            data?.username?.includes("@") && (
              <div className="col-12 ">
                <span className="email-text">
                  {props.intl.formatMessage({ id: "phone_number" })}{" "}
                </span>
                <Form.Item
                  name="phoneNumber"
                  rules={[
                    {
                      required: true,
                      message: `${props.intl.formatMessage({
                        id: "phone_number_required",
                      })}`,
                    },
                  ]}
                >
                  <PhoneInput
                    inputProps={{
                      name: "phone",
                      required: true,
                    }}
                    onChange={(e) => {
                      setPhoneNumber(e.startsWith("+") ? e : "+" + e);
                    }}
                    countryCodeEditable={false}
                    inputStyle={{
                      width: "100%",
                      height: "56px",
                      backgroundColor: "#E6E7EB",
                      marginTop: "8px",
                    }}
                    country={"us"}
                    className="w-100 phonenumber_field countries"
                    inputClass="contact_field"
                  />
                </Form.Item>
              </div>
            )}
          {accessToken.user_type !== "contractor" &&
            !data?.username?.includes("@") && (
              <div className="col-12 ">
                <span className="email-text">
                  {props.intl.formatMessage({ id: "email" })}{" "}
                </span>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: `${props.intl.formatMessage({
                        id: "email_required",
                      })}`,
                    },
                  ]}
                >
                  <Input
                    placeholder={props.intl.formatMessage({
                      id: "example@gmail.com",
                    })}
                    style={{
                      height: "56px",
                      backgroundColor: "#E6E7EB",
                      marginTop: "8px",
                    }}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Item>
              </div>
            )}
        </div>
        <div className="row ">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <span className="email-text">
              {props.intl.formatMessage({ id: "state" })}
            </span>
            <Form.Item
              name="state"
              rules={[
                {
                  required: true,
                  message: `${props.intl.formatMessage({
                    id: "state_required",
                  })}`,
                },
              ]}
            >
              <Select
                showSearch
                style={{
                  width: "100%",
                }}
                placeholder={props.intl.formatMessage({ id: "state" })}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={usStates}
                onSelect={(e) => handleState(e)}
              />
            </Form.Item>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <span className="email-text">
              {props.intl.formatMessage({ id: "city" })}
            </span>
            <Form.Item
              name="city"
              rules={[
                {
                  required: true,
                  message: `${props.intl.formatMessage({
                    id: "city_required",
                  })}`,
                },
              ]}
            >
              <Select
                showSearch
                style={{
                  width: "100%",
                }}
                placeholder={props.intl.formatMessage({ id: "state" })}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={Cities}
                onSelect={(e) => setCity(e)}
              />
            </Form.Item>
          </div>
        </div>
        <div className="row ">
          <div className="col-12">
            <div className="d-flex justify-content-between">
              <div>
                <span className="email-text">
                  {props.intl.formatMessage({ id: "location" })}
                </span>
              </div>
              <div onClick={handleGetLocation}>
                <span>
                  <EnvironmentOutlined style={{ color: "#F77E0B" }} />
                  <span
                    className="trad-location ms-1"
                    style={{ userSelect: "none" }}
                  >
                    {props.intl.formatMessage({ id: "current_location" })}
                  </span>
                </span>
              </div>
            </div>

            <Form.Item
              name="location"
              rules={[
                {
                  required: false,
                  message: `${props.intl.formatMessage({
                    id: "location_required",
                  })}`,
                },
              ]}
            >
              <Input
                name={props.intl.formatMessage({ id: "location" })}
                type="text"
                placeholder={props.intl.formatMessage({ id: "set_location" })}
                onChange={(e) =>
                  setLocation({ ...location, address: e.target.value })
                }
                style={{
                  height: "56px",
                  backgroundColor: "#E6E7EB",
                  marginTop: "8px",
                }}
              />
            </Form.Item>
          </div>
        </div>
        <div style={{ height: "300px" }}>
          <ProfileImageUpload1
            profilePic={profilePic}
            setProfilePic={setProfilePic}
          />
        </div>
        <div className="d-flex justify-content-end mt-5">
          {data &&
            (accessToken.user_type === "contractor" ||
              accessToken.user_type === "tradesman") &&
            props.current < 5 && (
              <Button
                style={{
                  width: "120px",
                  backgroundColor: isLoading ? "#cdd9df77" : "#F77E0B",
                  height: "56px",
                  color: "white",
                }}
                type="submit"
                htmlType="submit"
              >
                {props.intl.formatMessage({ id: "next" })}
              </Button>
            )}
          {data && accessToken?.user_type === "homeowner" && (
            <Button
              style={{
                width: "120px",
                backgroundColor: isLoading ? "#cdd9df77" : "#F77E0B",
                height: "56px",
                color: "white",
              }}
              type="submit"
              htmlType="submit"
            >
              {props.intl.formatMessage({ id: "update" })}
            </Button>
          )}
        </div>
      </Form>
      <TwoFaModal handleVerifyOtp={handleVerifyOtp} loading={loading} />
    </div>
  );
}

export default injectIntl(FirstPage);
