import { Button, Form, Input } from "antd";
import React, { useState, useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import Header from "../../components/Header/Header";
import { useSelector } from "react-redux";
import {
  BankTokenCreationAeroPay,
  aeroPayAccountSuccess,
  getUserBankDetailsAeroPay,
} from "../../api/bankApi/AddBankAccount";
import Loader from "../../components/GeneralComponents";
import { BackButton } from "../../components/General/BackButton";
import { openWidget } from "aerosync-web-sdk";
import { useNavigate } from "react-router";

const AddHomAccount = (props) => {
  const searchParams = new URLSearchParams(window.location.search);
  const path = searchParams.get("path");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const userTypeData = useSelector((state) => state?.common?.fullUser);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTokenAndOpenWidget = async () => {
      setLoading(true);
      if (userTypeData?.id) {
        let token = await getUserBankDetailsAeroPay(userTypeData?.id);
        if (token) {
          openAerosyncWidget(token);
        }
        setLoading(false);
      }
    };
    fetchTokenAndOpenWidget();
  }, [userTypeData]);

  const handleSubmit = async (values) => {
    setLoading(true);
    const body = {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email?.trim()?.toLowerCase(),
      phone_number: values.phoneNumber?.replace(/\D/g, ""),
      userId: userTypeData?.id,
    };
    let token = await BankTokenCreationAeroPay(body, path);
    if (token) {
      openAerosyncWidget(token);
    }
    setLoading(false);
  };

  const openAerosyncWidget = (accessToken) => {
    let token = accessToken;
    let deeplink = "https://gigmi.page.link/d6o5"; // Unique URL that points to the specific page within the mobile app
    let consumerId = userTypeData?.id; // Unique ID of the consumer
    let widgetRef = openWidget({
      id: "widget",
      iframeTitle: "Connect",
      environment: "staging", // staging, production
      token: token,
      style: {
        width: "375px",
        height: "688px",
        bgColor: "#000000",
        opacity: 0.7,
      },
      deeplink: deeplink,
      consumerId: consumerId,
      onEvent: function () {},
      onLoad: function () {},
      onError: function () {},
      onSuccess: function (event) {
        aeroPayAccountSuccess({
          userId: userTypeData?.id,
          user_id: event.user_id,
          user_password: event.user_password,
        });
      },
      onClose: function () {
        navigate("/payment-options");
      },
    });
    widgetRef.launch();
  };

  return (
    <>
      {loading && (
        <div className="search-loader">
          <Loader />
        </div>
      )}
      <div id="widget"></div>
      <BackButton />
      <div className="container paymentscreen">
        <Header />
        <div className="paymentscreen-inner">
          <div className="d-flex justify-content-center align-items-center getting-payment-heading shadow border text-center">
            <span>
              <FormattedMessage id="add_bank_info" />
            </span>
          </div>
          {userTypeData?.first_name && (
            <Form
              onFinish={handleSubmit}
              form={form}
              layout="vertical"
              initialValues={{
                firstName: userTypeData?.first_name || "",
                lastName: userTypeData.last_name || "",
                phoneNumber: userTypeData?.phone || "",
                confirmPhoneNumber: "",
                email: userTypeData?.email || "",
              }}
            >
              <div className="row">
                <div className="col-6">
                  <Form.Item
                    name="firstName"
                    label={props.intl.formatMessage({ id: "first_name" })}
                    rules={[
                      {
                        required: true,
                        message: `${props.intl.formatMessage({
                          id: "required",
                        })}`,
                      },
                    ]}
                  >
                    <Input
                      placeholder={props.intl.formatMessage({
                        id: "first_name",
                      })}
                      className="input-field"
                    />
                  </Form.Item>
                </div>
                <div className="col-6">
                  <Form.Item
                    name="lastName"
                    label={props.intl.formatMessage({
                      id: "last_name",
                    })}
                    rules={[
                      {
                        required: true,
                        message: `${props.intl.formatMessage({
                          id: "required",
                        })}`,
                      },
                    ]}
                  >
                    <Input
                      placeholder={props.intl.formatMessage({
                        id: "last_name",
                      })}
                      className="input-field"
                    />
                  </Form.Item>
                </div>
              </div>
              <Form.Item
                name="email"
                label={props.intl.formatMessage({ id: "email" })}
                rules={[
                  {
                    required: true,
                    message: `${props.intl.formatMessage({ id: "required" })}`,
                  },
                ]}
              >
                <Input
                  type="email"
                  placeholder={props.intl.formatMessage({ id: "email" })}
                  className="input-field"
                />
              </Form.Item>

              <Form.Item
                name="phoneNumber"
                label={props.intl.formatMessage({ id: "phone_number" })}
                rules={[
                  {
                    required: true,
                    message: `${props.intl.formatMessage({ id: "required" })}`,
                  },
                  {
                    pattern: new RegExp(/^[0-9]*(?:\.[0-9]*)?$/),
                    message: `${props.intl.formatMessage({
                      id: "only_numeric",
                    })}`,
                  },
                ]}
              >
                <Input
                  placeholder={props.intl.formatMessage({ id: "phone_number" })}
                  className="input-field"
                />
              </Form.Item>

              <Form.Item
                name="confirmPhoneNumber"
                label={props.intl.formatMessage({ id: "confirm_phone" })}
                rules={[
                  {
                    required: true,
                    message: `${props.intl.formatMessage({ id: "required" })}`,
                  },
                  {
                    pattern: new RegExp(/^[0-9]*(?:\.[0-9]*)?$/),
                    message: `${props.intl.formatMessage({
                      id: "only_numeric",
                    })}`,
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("phoneNumber") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        `${props.intl.formatMessage({
                          id: "phone_not_matched",
                        })}`
                      );
                    },
                  }),
                ]}
              >
                <Input
                  className="input-field"
                  placeholder={props.intl.formatMessage({
                    id: "confirm_phone",
                  })}
                />
              </Form.Item>

              <div className="d-flex justify-content-end">
                <Form.Item>
                  <Button
                    type="submit"
                    htmlType="submit"
                    className="submit-button"
                  >
                    <FormattedMessage id="submit" />
                  </Button>
                </Form.Item>
              </div>
            </Form>
          )}
        </div>
      </div>
    </>
  );
};
export default injectIntl(AddHomAccount);
