import { setBankAccountList } from "../../actions/bankActions";
import { Axios } from "../../utils/axiosMiddleware";
import { SimpleCatchError } from "../../utils/Helper";

export const getAccountList = async (id, dispatch) => {
  try {
    const response = await Axios.get(`gigwage/receiving/bank-account/${id}`);
    dispatch(
      setBankAccountList(
        response?.data?.accounts?.filter(
          (item) => item?.deactivated_at === null
        )
      )
    );
  } catch (e) {
    SimpleCatchError(e);
  }
};

export const deActivateAccount = async (id) => {
  try {
    await Axios.delete(`gigwage/deactivate/receiving/bank-account/${id}`);
    return "deleted";
  } catch (e) {
    SimpleCatchError(e);
  }
};
